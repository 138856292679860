import React, {useState} from 'react';
import api from 'api/API.js';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { Text, PhoneNumber, Checkbox, LoadingButton } from 'parts/Parts.jsx';

export default function UserForm(props) {
  let [userObj, setUserObj] = useState(api.user.getCachedUser());
  let [validated, setValidated] = useState(false);

  async function submit(event) {
    event.preventDefault();

    let toSend = {...userObj};
    if (toSend.phoneNumber) toSend.phoneNumber = toSend.phoneNumber.replace(/[() -]/g, "");

    if (! manuallyValidate()) {
      event.stopPropagation();
      return;
    }
    setValidated(true);
    let updatedUser = await api.user.updateUser(toSend);
    toast.success(" Successfully updated user");
    if (props.callback) props.callback(updatedUser);
  }

  function manuallyValidate() {
    let ret = true;
    const toCheck = Object.keys(userObj);
    for (let x = 0; x < toCheck.length; x++) {
      const id = toCheck[x];
      const item = window.$("#" + id);
      const value = userObj[id];
      
      if (Object.keys(item).length < 1) {
        continue;
      }
      
      if (id === "phoneNumber" && userObj.canText && value.replace(/[() _-]/g, "").length !== 12) {
        item.addClass("is-invalid");
        ret = false;
      }
      if (id === "email") {
        if ((value.match(/\./g) || []).length === 0 || (value.match(/@/g) || []).length === 0 || value.length < 7) {
          item.addClass("is-invalid");
          console.log("invalid email!");
          ret = false;
        }
      }
      if (value.length < 1) {
        item.addClass("is-invalid");
        ret = false;
      }
    }

    return ret;
  }

  function onChange(event) {
    userObj[event.target.id] = event.target.value || event.target.checked;
    setUserObj({...userObj});
  }

  function onCheckbox(event) {
    userObj[event.target.id] = event.target.checked;
    setUserObj({...userObj});
  }
  
  return (
    <Form validated={validated} className="p-3" style={{width: '100%'}} onSubmit={submit}>
      <Form.Row>
        <Text required={true} obj={userObj} handler={onChange} placeholder="Enter first name" invalid="Please enter your first name" label="First Name" id="firstName" />
        <Text required obj={userObj} handler={onChange} placeholder="Enter last name" invalid="Please enter your last name" label="Last Name" id="lastName" />
      </Form.Row>
      <Form.Row>
        <PhoneNumber required={false} id="phoneNumber" obj={userObj} handler={onChange} placeholder="Enter phone number" label="Mobile Phone Number"  help="We'll never share your phone number with anyone else, nor will we ever text you without asking." />
        <Text required id="email" obj={userObj} handler={onChange} type="email" placeholder="Enter email address" invalid="Please a valid email address" label="Email Address" help="We'll never share your email with anyone else." />
      </Form.Row>
      
      <Form.Row>
        <Checkbox id="canText" obj={userObj} handler={onCheckbox}>Text me when my server is ready</Checkbox>
      </Form.Row>
      <hr className="white" />
      <div className="col-12 mb-3 px-0">
        <small>By clicking submit, you acknowledge that you have read and agree to the <a href="https://ferrofluidgames.com/tos.html">terms of service</a> and <a href="https://ferrofluidgames.com/privacy.html">privacy policy</a></small>
      </div>
      <div className="col-12">
        <LoadingButton type="submit" className="btn btn-primary float-right" id="submitButton" onClick={(event) => {return submit(event);}}>Submit</LoadingButton>
      </div>
    </Form>
  );
}
