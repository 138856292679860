import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function CustomModal(props) {

  /*
    Props:
    visible*: whether the modal is visible or not
    onClose: run necessary code when closing the modal
    onOpen: run necessary code when opening the modal
    
    title*: the shown title of the modal. human-readable
    children*: the contents of the modal.
    size: width of the modal (sm, lg, xl)
    submit: function to run for the optional submit button
  */

  let footLeft = null;
  let footRight = null;
  function mapper(child) {
    let p = Object.getPrototypeOf(child);
    if (child.type && child.type.description == "react.fragment") {
      React.Children.map(child.props.children, mapper);
      return;
    }
    if (!child.type && p.isPrototypeOf(FootLeft)) {
      console.log(child);
      footLeft = child;
      return;
    }
    /* if (child.type.description == "FootRight") {
      footRight = child;
      return;
    } */
  }
  React.Children.map(props.children, mapper);

  const {visible, onClose, onOpen, size, title, children, submit, enforceFocus, ...leftovers} = props;

  console.log(footLeft? footLeft.props.children : "no footLeft");
  return (
    <Modal show={visible} onHide={onClose} onShow={onOpen} size={size || "sm"} enforceFocus={enforceFocus} centered {...leftovers}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {visible ? children : null}
      </Modal.Body>
      <Modal.Footer>
        {footLeft ? footLeft.props.children : 
         <Button variant="secondary" onClick={onClose}>Close</Button> }
        {submit ? <Button variant="primary" className="ml-auto waves-effect waves-themed" onClick={e => {submit(e); e.preventDefault();}}>Submit</Button> : null}
      </Modal.Footer>
    </Modal>
  );
}

function FootLeft(props) {
  return props.children;
}
FootRight.prototype.displayName = "FootLeft";


function FootRight(props) {
  return props.children;
}
FootRight.prototype.displayName = "FootRight";

console.log((<FootLeft/>).type);

export { FootLeft, FootRight }
// footer settings: className="modal-footer border-faded border-left-0 border-right-0 border-bottom-0" style={{'justifyContent': 'flex-end', 'flexDirection': 'row', 'display': 'flex'}}
// ?: if (props.onClose) props.onClose();
