import React, {useState, useEffect} from 'react';
import { sleep } from 'api/util.js';

function Console(props) {
  const [isBottom, setBottom] = useState(true);

  useEffect(() => {
    if (isBottom) {
      let textarea = document.getElementById('logBox');
      if (textarea) {
        sleep(100).then(() => {textarea.scrollTop = textarea.scrollHeight;});
      }
    }
  });
  
  if (props.data) {
    return (
      <div className="card col-md-11 m-1 m-3-lg p-0">
        {props.title ? (<div className="card-header"><h3>{props.title || "Console"}</h3></div>) : null}
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="row justify-content-center"><textarea id="logBox" readOnly={true} className="col-12 col-lg-11 dark-mode" onScroll={() => {
              let textarea = document.getElementById('logBox');
              if (textarea.scrollTop + 670 >= textarea.scrollHeight) {
                setBottom(true);
              } else {
                setBottom(false);
              }
            }} style={{resize: 'none', height: '42rem', width: '100%'}} value={props.data || "Loading, please wait..."}/></div>
          </li>
          <li className="list-group-item">
            <div className="row justify-content-around">
              <input id="commandBox" type="text" onKeyPress={event => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  let box = document.getElementById("commandBox");
                  props.sendText(box.value);
                  box.value = "";
                }
              }} className="col-10 dark-mode" placeholder="Console command..."/>
              <button className="btn btn-primary col-2 col-xl-1" onClick={event => {
                let box = document.getElementById("commandBox");
                props.sendText(box.value);
                box.value = "";
              }}>Send</button>
            </div>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <div className="card col-md-11 m-1 m-3-lg p-0">
        {props.title ? (<div className="card-header"><h3>{props.title || "Console"}</h3></div>) : null}
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="card d-flex justify-content-center align-content-center">
              <picture style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <source srcSet="/img/ff_spinner.webp" type="image/webp" media="(min-width: 600px)" />
                <source srcSet="/img/circles.svg" type="image/svg" />
                <img src="/img/circles.svg" alt="Loading..." />
              </picture>
            </div>
          </li>
          <li className="list-group-item">
            <div className="row justify-content-around">
                <input id="commandBox" type="text" disabled={true} className="col-10 dark-mode" placeholder="Console command..."/>
                <button className="btn btn-primary col-2 col-xl-1 disabled" disabled>Send</button>
              </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default Console;
